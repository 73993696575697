.panel-control .nav-tabs > li:not(.active) {
    opacity: 1;
    color: white
}

.panel-control .nav-tabs > li > a {
    border-radius: 0;
    margin-right: 0;
    height: 100%;
    line-height: 33px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-size: 14px;
    color: black;
    font-weight: 600;
}

.panel-control .nav-tabs {
    min-height: 34px;
}

.panel-heading .panel-control {
    height: 100%;
    position: relative;
    float: right;
    padding: 0 15px;
}

.panel-primary .panel-heading, .panel-sub-heading,
.panel-primary .panel-footer,
.panel-primary.panel-colorful {
    background-color: #46546C;
    border-color: #46546C;
    color: #fff;
}

.panel-title {
    font-size: 18px;
    display: inline;
}

.panel-sub-heading {
    position: relative;
    min-height: 45px;
    padding: 0;
    color: #2b425b;
}

.panel-heading {
    background-color: #ebf5ff;
    border-top: 1px solid #c7d3dc;
    border-left: 1px solid #c7d3dc;
    border-right: 1px solid #c7d3dc;
}

.panel-body {
    border-left: 1px solid #c7d3dc;
    border-right: 1px solid #c7d3dc;
}

.panel .panel-sub-heading
{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding-left: 8px;
}

.panel .panel-sub-heading .panel-control
{
    float: none !important;
}

#container .table th {
    font-weight: 400;
    border-bottom: 0px solid #e9e9e9;
}

.panel .timeline-label {
    margin-left: 70px;
}

.timeline-icon {
}

.brand-text {
    font-size: 16px;
    font-weight: 400;
}

#mainnav {
    height: 100%;
    background-color: #000000;
    background-image: url("/images/wallpaper.jpg");
    background-repeat: no-repeat;
}

#mainnav .list-header {
    font-weight: 400;
}

#mainnav-menu li ul li:last-child
{
    padding-bottom: 10px;
}

#mainnav-menu a {
    display: block;
    color: #ddd;
    padding: 8px 20px;
}

#mainnav-menu a strong {
    font-weight: 400;
}

#mainnav-menu > li:hover
{
    background-color: rgba(255,255,255,0.2);
}

#mainnav-menu > li
{
    background-color: rgba(0,0,0,0.15);
}
.nano
{
    background-color: rgba(0,0,0,0.15);
}

#mainnav-menu > li > a.hover {
    color: #eee;
}

#mainnav-menu ul a, .menu-popover .sub-menu ul a {
    padding: 6px 20px 6px 48px;
}

.timeline:before {
    left: 29px;
    top: 5px;
    border-radius: 50%;
    border: 1px solid #bec6ce;
}

.timeline:after {
    border:none;
}

.timeline-header .timeline-header-title {
    display: block;
}

.timeline-stat {
    width: 60px;
}

.timeline-time {
    padding: 4px 3px;
    font-size: 10px;
    max-width: 60px;
}

#page-content {
    padding: 15px 15px 0;
    max-width: 1500px;
    margin: 0 auto;
}

.panel-heading-buttons {
    float: right;
    padding-top: 10px;
    padding-right: 10px;
}

.username-initials {
    text-transform: uppercase;
    background-color: #a9a9a9;
    border-radius: 50%;
    color: #ffffff;
    display: inline-block;
    width: 50px;
    height: 50px;
    text-align: center;
    padding-top: 10px;
    font-size: 21px;
    padding-left: 1px;
    font-weight: 700;
}

#mainnav-menu > li:hover {
    background-color: rgba(255,255,255,0.2);
}

#mainnav-menu > .active {
    background-color: rgba(255,255,255,0.15);
}