.mainnav-profile .profile-wrap {
    background-image: none;
}

#mainnav-container {
    padding-top: 0;
    z-index: 999;
}

.panel-body .panel-section h4 {
    color: #00A15E;
    padding-bottom: 10px;
}

#page-content {
    max-width: 1500px;
    margin: 0 auto;
}

.editor-container {
    width: 100%;
    min-height: 800px;
}

.template-select {
    margin-bottom: 20px;
}

.template-select {
    display: block;
    text-align: center;
    height:340px;
    overflow:hidden;
}

.template-select .wrapper {
    border: 2px solid #dedede;
    height:290px;
    overflow: hidden;
}

.template-select.current-template .wrapper {
    border-color:#00A15E;
}

.template-select:hover .wrapper, .template-select:hover .wrapper{
    border-color: #00B0E8;
}

.dropzone {
    border: 1px solid #e9e9e9;
}

.table-click tr {
    cursor: pointer;
}

/*V Tooltips */
.tooltip {
    display: none;
    opacity: 0;
    transition: opacity .05s;
    pointer-events: none;
    padding: 4px;
    z-index: 10000;
}

.tooltip-content {
    background: rgba(0,0,0,0.75);
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
}

.tooltip.tooltip-open-transitionend {
    display: block;
}

.tooltip.tooltip-after-open {
    opacity: 1;
}

.editor-list-menu {
    margin:0 -20px;
}

.editor-list-menu .list-group-item {
    padding:10px 20px;
}

/* .no-gutter */
.no-gutter.row, .container-fluid.no-gutter > .row {
    margin-left:0;
    margin-right:0;
}

.no-gutter > [class*='col-'], .container-fluid.no-gutter > .row > [class*='col-'] , .container-fluid.no-gutter {
    padding-left:0;
    padding-right:0;
}

/* .gutter-xs */
.gutter-xs.row, .container-fluid.gutter-xs > .row {
    margin-left:-5px;
    margin-right:-5px;
}
.gutter-xs > [class*='col-'], .container-fluid.gutter-xs > .row > [class*='col-'], .container-fluid.gutter-xs {
    padding-left:5px;
    padding-right:5px;
}


.editor-menu .thumbnail {
    margin-bottom:10px;
}

.editor-menu .menu-block {
    margin-bottom:10px;
    cursor:pointer;
}

.block-options {
    position: absolute;
    top:0;
    right:-30px;
    width:30px;
}

.block-options ul {
    margin:0 0 0 5px;
    background-color:rgba(0,0,0,0.75);
    border-radius: 20px;
}

.block-options a {
    display:block;
    color:#fff;
    padding: 5px;
}


.block-options a:hover {
    color:#00B0E8;
}

.menu-images .thumbnail {
    min-height:93px;
}

.cke_editable:focus {
    outline:none;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
}

.menu-images .pagination {
    margin-top:10px;
}

.et-wrapper.toast-info {
    background-color:#00B0E8;
    margin-top:10px;
    border-radius:20px;
    color:#fff;
    padding: 5px 20px;
}

.et-wrapper.toast-danger {
    background-color:#ef5350;
    margin-top:10px;
    border-radius:20px;
    color:#fff;
    padding: 5px 20px;
}


.mar-lg-top {
    margin-top:30px;
}

.mar-lg-ver {
    margin-top:30px;
    margin-bottom:30px;
}

.current-template {
    font-weight:bold;
}


/* Vue MultiSelect */
.multiselect__tags, .multiselect--above .multiselect__content, .multiselect--below .multiselect__content {
    border-radius:0;
}

.multiselect, .multiselect__tags {
    min-height:30px;
}

.multiselect__select, .multiselect__spinner {
    height:32px;
}

.multiselect__tags {
    padding: 4px 40px 0 4px;
}

.multiselect__input, .multiselect__single, .multiselect__tag {
    margin-bottom:4px;
}

.multiselect__content, .multiselect__element, .multiselect--active {
    z-index: 9999;
}

/*.input-group .form-control .multiselect {*/
    /*z-index:2;*/
/*}*/

.editor-container .alert {
    margin-bottom:0;
}

.btn-success-basic, .btn-success, .btn-success:focus, .btn-success.disabled:hover, .btn-success:disabled:hover, .btn-hover-success:hover, .btn-hover-success:active, .btn-hover-success.active, .btn.btn-active-success:active, .btn.btn-active-success.active, .dropdown.open > .btn.btn-active-success, .btn-group.open .dropdown-toggle.btn.btn-active-success {
    background-color: #41b883;
    border-color:#41b883;
}

.btn-success:hover, .btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
    background-color: #3c7f4f;
    border-color:#3c7f4f;
}

.flatpickr-input.form-control[readonly] {
    background-color:#fff;
}

.alert-warning {
    background:#ffce88;
    border-color:#ffb954;
}

.progress-bar-warning {
    background:#ffb954;
}

.text-warning {
    color:#e88900;
}

.alert .progress {
    margin-bottom:0;
}

.well {
    background:inherit;
}

.well h3 {
    margin-top:0;
}

.campaign-well {
    min-height:160px;
}